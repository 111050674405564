.youtube-link-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
  }
  
  .youtube-link-header {
    font-size: 24px;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .youtube-link-form {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  
  .youtube-link-input {
    flex-grow: 1;
    padding: 8px;
    margin-right: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .youtube-link-btn {
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .add-btn {
    background-color: #28a745;
    color: white;
  }
  
  .edit-btn {
    background-color: #007bff;
    color: white;
    margin-right: 5px;
  }
  
  .delete-btn {
    background-color: #dc3545;
    color: white;
  }
  
  .youtube-link-list {
    list-style-type: none;
    padding: 0;
  }
  
  .youtube-link-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .youtube-link-item a {
    flex-grow: 1;
    text-decoration: none;
    color: #333;
  }
  .youtube-thumbnail {
    width: 120px; /* or any desired width */
    height: auto;
    display: block;
    margin-bottom: 10px;
  }
  .youtube-link-container {
  padding: 20px;
  max-width: 600px;
  margin: 0 auto;
}

.youtube-link-header {
  font-size: 24px;
  margin-bottom: 20px;
}

.youtube-link-form {
  display: flex;
  margin-bottom: 20px;
}

.youtube-link-input {
  flex-grow: 1;
  padding: 10px;
  font-size: 16px;
  margin-right: 10px;
}

.youtube-link-btn {
  padding: 10px 20px;
  font-size: 16px;
}

.youtube-link-list {
  list-style: none;
  padding: 0;
}

.youtube-link-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 10px;
}

.youtube-link-item a {
  margin-right: 10px;
}

.youtube-link-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
}

.youtube-link-header {
  font-size: 24px;
  margin-bottom: 20px;
}

.youtube-link-form {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 500px;
}

.youtube-link-input {
  width: 100%;
  font-size: 1rem;
  line-height: 1.5;
  padding: 0.5rem;
  box-sizing: border-box;
  margin-bottom: 1rem;
  resize: vertical; /* Allow vertical resizing */
}


.youtube-link-btn {
  padding: 0.5rem 1rem;
  font-size: 1rem;
  margin-top: 1rem;
}

.youtube-link-list {
  list-style: none;
  padding: 0;
}

.youtube-link-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.youtube-link-item a {
  margin-right: 10px;
}

.youtube-thumbnail {
  width: 120px;
  height: auto;
  display: block;
}
.youtube-description {
  font-size: 14px;
  color: #333;
  text-align: center;
}



  