body.en-page-ente-nadu { 
    background: linear-gradient(to bottom right, rgb(37, 202, 37), rgb(28, 54, 39));
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100%;
    
  }
  body.home-page {
    background: rgb(157, 243, 167);
    font-family: 'Josefin Sans', sans-serif
  } 