/* Login Container - Centered and Artistic Background */
.login-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-image: url("https://unsplash.com/s/photos/artistic-background"); /* Replace with your artistic background image URL */
  background-size: cover;
  background-position: center;
}

/* Login Header - Stylish Text Treatment */
.login-header {
  font-size: 24px;
  margin-bottom: 20px;
  font-family: cursive, serif; /* Artistic fonts for a handwritten style */
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2); /* Subtle text shadow for depth */
  color: #fff; /* White text for readability on artistic background */
}

/* Login Form - Translucent with Soft Border */
.login-form {
  width: 300px;
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  background-color: rgba(255, 255, 255, 0.1); /* Slightly transparent white background */
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1%;
}

/* Form Group - No Change (already simple) */

/* Label - Stylish Text Treatment */

/* Form Control - Soft Border and Subtle Shadow */
.form-control {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ddd; /* Light gray border */
  border-radius: 4px;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1); /* Subtle inset shadow for a textured look */
}

/* Login Button - Gradient with Clearer Hover Effect */
.btn-login {
  width: 50%;
  padding: 10px;
  font-size: 16px;
  border: none;
  border-radius: 10px;
  background: #f0e68c; /* Light yellow base color */
  background-image: linear-gradient(to right, #f0e68c 0%, #e6de7a 50%, #f5f5f5 100%); /* Gradient for a textured button */
  color: #333; /* Dark gray text */
  cursor: pointer;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2); /* Subtle text shadow for depth */
  transition: background 0.3s ease-in-out; /* Smoother hover transition */
}

.btn-login:hover {
  background-position: left; /* Animate gradient on hover */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* Add a slight outer shadow on hover for more emphasis */
}

.ui-btn {
  --btn-default-bg: rgb(157, 243, 167);
  --btn-padding: 15px 20px;
  --btn-hover-bg: rgb(157, 243, 167);
  --btn-transition: .3s;
  --btn-letter-spacing: .1rem;
  --btn-animation-duration: 1.2s;
  /* --btn-shadow-color: rgba(0, 0, 0, 0.137); */
  /* --btn-shadow: 0 2px 10px 0 var(--btn-shadow-color); */
  --hover-btn-color: #020202;
  --default-btn-color: #000000;
  --font-size: 16px;
  /* 👆 this field should not be empty */
  --font-weight: 600;
  --font-family: Menlo,Roboto Mono,monospace;
  /* 👆 this field should not be empty */
}

/* button settings 👆 */

.ui-btn {
  box-sizing: border-box;
  /* padding: var(--btn-padding); */
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--default-btn-color);
  font: var(--font-weight) var(--font-size) var(--font-family);
  background: var(--btn-default-bg);
  border: none;
  cursor: pointer;
  transition: var(--btn-transition);
  overflow: hidden;
  box-shadow: var(--btn-shadow);
}

.ui-btn span {
  letter-spacing: var(--btn-letter-spacing);
  transition: var(--btn-transition);
  box-sizing: border-box;
  position: relative;
  background: inherit;
}

.ui-btn span::before {
  box-sizing: border-box;
  position: absolute;
  content: "";
  background: inherit;
}

.ui-btn:hover, .ui-btn:focus {
  background: var(--btn-hover-bg);
}

.ui-btn:hover span, .ui-btn:focus span {
  color: var(--hover-btn-color);
}

.ui-btn:hover span::before, .ui-btn:focus span::before {
  animation: chitchat linear both var(--btn-animation-duration);
}

@keyframes chitchat {
  0% {
    content: "#";
  }

  5% {
    content: ".";
  }

  10% {
    content: "^{";
  }

  15% {
    content: "-!";
  }

  20% {
    content: "#$_";
  }

  25% {
    content: "№:0";
  }

  30% {
    content: "#{+.";
  }

  35% {
    content: "@}-?";
  }

  40% {
    content: "?{4@%";
  }

  45% {
    content: "=.,^!";
  }

  50% {
    content: "?2@%";
  }

  55% {
    content: "\;1}]";
  }

  60% {
    content: "?{%:%";
    right: 0;
  }

  65% {
    content: "|{f[4";
    right: 0;
  }

  70% {
    content: "{4%0%";
    right: 0;
  }

  75% {
    content: "'1_0<";
    right: 0;
  }

  80% {
    content: "{0%";
    right: 0;
  }

  85% {
    content: "]>'";
    right: 0;
  }

  90% {
    content: "4";
    right: 0;
  }

  95% {
    content: "2";
    right: 0;
  }

  100% {
    content: "";
    right: 0;
  }
}
