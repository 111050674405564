.admin-page-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.admin-page-header {
    font-size: 36px;
    font-weight: bold;
    color: #333;
    text-shadow: 2px 2px #ccc;
    margin-bottom: 30px;
}

.admin-page-buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 50%;
}

.admin-page-button {
    width: 45%;
    padding: 12px;
    margin: 0 5px;
    border-radius: 12px;
    background-color: #ffc107;
    color: #fff;
    font-size: 24px;
    border: none;
    cursor: pointer;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
    transition: transform 0.3s;
}

.admin-page-button:hover {
    transform: translateY(-5px);
}

.admin-page-logout {
    width: 45%;
    padding: 12px;
    margin: 0 5px;
    border-radius: 12px;
    background-color: #e64a19;
    color: #fff;
    font-size: 24px;
    border: none;
    cursor: pointer;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
    transition: transform 0.3s;
}

.admin-page-logout:hover {
    transform: translateY(-5px);
}
@media only screen and (max-width: 768px) {
    .admin-page-buttons {
        width: 80%; /* Adjust width for smaller screens */
    }
}

@media only screen and (max-width: 480px) {
    .admin-page-buttons {
        flex-direction: column; /* Stack buttons vertically on smaller screens */
    }
    .admin-page-button, .admin-page-logout {
        width: 100%; /* Full width for buttons on smaller screens */
        margin-bottom: 10px; /* Add space between stacked buttons */
    }
}