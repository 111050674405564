/* Navibar.css */

@keyframes breathing {
    0% { transform: scale(1); }
    50% { transform: scale(1.1); }
    100% { transform: scale(1); }
  }
  
  .breathing-icon {
    animation: breathing 3s infinite;
  }
  