.en-home-table {
    width: 100%;
    margin-top: 0px;
    height: auto;
}

.en-home-table td {
    padding-top: 40px;
    vertical-align: middle;
    text-align: center;
}

.en-home-table img {
    width: 360px;
}

.en-page-footer {
    padding: 2%;
    text-align: center;
    color: rgb(0, 0, 0);
    margin-top: 10px;
}

.en-page-action1 {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translateY(-50%);
    padding-top: 300px;
    padding-bottom: 0%;
}

.ente-nadu {
    margin-top: 2%;
}

.ente-nadu1 {
    width: 800px !important;
    height: auto !important;
    margin-bottom: 0%;
}
.ente-nadu3 {
    width: 130px !important;
    height: auto !important;
    margin-bottom: 0%;
}
button {
    --primary-color: #021f08;
    --secondary-color: #fff;
    --hover-color: #1d9a36;
    --arrow-width: 15px;
    --arrow-stroke: 3px;
    box-sizing: border-box;
    border: 0;
    border-radius: 20px;
    color: var(--secondary-color);
    padding: 0.8em 1.5em;
    background: var(--primary-color);
    display: flex;
    transition: 0.2s background;
    align-items: center;
    gap: 0.6em;
    font-weight: bold;
}

button .arrow-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}

button .arrow {
    margin-top: 1px;
    width: var(--arrow-width);
    background: var(--primary-color);
    height: var(--arrow-stroke);
    position: relative;
    transition: 0.2s;
}

button .arrow::before {
    content: "";
    box-sizing: border-box;
    position: absolute;
    border: solid var(--secondary-color);
    border-width: 0 var(--arrow-stroke) var(--arrow-stroke) 0;
    display: inline-block;
    top: -3px;
    right: 3px;
    transition: 0.2s;
    padding: 3px;
    transform: rotate(-45deg);
}

button:hover {
    background-color: var(--hover-color);
}

button:hover .arrow {
    background: var(--secondary-color);
}

button:hover .arrow:before {
    right: 0;
}

@media (max-width: 768px) {
    body.en-page-ente-nadu { 
        background: linear-gradient(to bottom right, rgb(37, 202, 37), rgb(28, 54, 39));
        background-repeat: no-repeat;
        background-size: cover;
        min-height: 100vh;
        min-height:fit-content;
        
      }
    .en-home-table img {
        width: 100%;
    }

    .en-page-action1 {
        padding-top: 100%;
    }

    .ente-nadu1 {
        width: 100% !important;
        height: auto !important;
    }
    .ente-nadu3 {
        width: 100px !important;
    }

    .en-page-footer {
        padding: 10%;
        text-align: center;
        color: rgb(0, 0, 0);
    }
}
@media (min-width: 769px) and (max-width: 1024px) {
    body.en-page-ente-nadu { 
        background: linear-gradient(to bottom right, rgb(37, 202, 37), rgb(28, 54, 39));
        background-repeat: no-repeat;
        background-size: cover;
        min-height: 100vh;
        margin: 0; 
    }

    .en-home-table img {
        width: 100%;
    }

    .en-page-action1 {
        padding-top: 400px;
    }

    .ente-nadu1 {
        width: 100% !important;
        height: auto !important;
    }
    .ente-nadu3 {
        width: 100px !important;
    }

    .en-page-footer {
        padding: 10%;
        text-align: center;
        color: rgb(0, 0, 0);
    }
}