@import url('https://fonts.googleapis.com/css?family=Josefin+Sans:400,400i,600,600i');

a{
  text-decoration:none
}
.header{
  position:relative;
  overflow:hidden;
  display:flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  align-content: flex-start;
  height:50vw;
  min-height:400px;
  max-height:450px;
  min-width:300px;
  color:#eee;
}
.header:after{
  content:"";
  width:100%;
  height:100%;
  position:absolute;
  bottom:0;
  left:0;
  z-index:-1;
 background: linear-gradient(to bottom, rgba(0,0,0,0.12) 40%,rgba(27,32,48,1) 100%);
}
.header:before {
    content: "";
    width: 100%;
    height: 150%;
    position: absolute;
    top: 0;
    left: 0;
    -webkit-backface-visibility: hidden;
    -webkit-transform: translateZ(0);
    backface-visibility: hidden;
    transform: translateZ(0);
    background: #1B2030 url('/public/background2.jpeg') 50% 0 no-repeat;
    background-size: contain;
    background-attachment: scroll;
    z-index: -2;
  }
.header a{
  color:#eee
}
.menu{
  display:block;
  width:40px;
  height:30px;
  border:2px solid #fff;
  border-radius:3px;
  position:absolute;
  right:20px;
  top:20px;
  text-decoration:none
}
.menu:after{
  content:"";
  display:block;
  width:20px;
  height:3px;
  background:#fff;
  position:absolute;
  margin:0 auto;
  top:5px;
  left:0;
  right:0;
  box-shadow:0 8px, 0 16px
}
.logo{
  border:2px solid #fff;
  border-radius:3px;
  text-decoration:none;
  display:inline-flex;
  align-items:center;
  align-content:center;
  margin:20px;
  padding:0px 10px;
  font-weight:900;
  font-size:1.1em;
  line-height:1;
  box-sizing:border-box;
  height:40px
}
.sides, .info{
  flex: 0 0 auto;
  width:50%
}
.info{
  width:100%;
  padding:15% 10% 0 10%;
  text-align:center;
  text-shadow:0 2px 3px rgba(0,0,0,0.2)
}
.author{
  display:inline-block;
  width:260px;
  height:90px;
  /* border-radius:50%; */
  background:url('/public/navlogo.png') center no-repeat;
  background-size:cover;
  box-shadow:0 2px 3px rgba(0,0,0,0.3);
  margin-bottom:3px
}
.info h4, .meta{
  font-size:0.7em
}
.meta{
  font-style:italic;
}
@keyframes grow{
  0% { transform: scale(1) translateY(0px)}
  50% { transform: scale(1.2) translateY(-400px)}
}
.content{  
  margin-top: 50px;
  padding:1% 10%;
  text-align:justify;
  font-weight:bold;
  font-size:larger;

}
.btn{
  color:#333;
  border:2px solid;
  border-radius:3px;
  text-decoration:none;
  display:inline-block;
  padding:5px 10px;
  font-weight:600
}

.twtr{
  margin-top:100px
}.btn.twtr:after{content:"\1F426";padding-left:5px}

@media only screen and (max-width: 767px) {
  .header {
    height: auto;
    min-height: auto;
    max-height: none;
    padding-bottom: 20px;
  }

  .header:before {
    height: 100%;
    background-size: cover;
  }
  .content{
    font-size: medium;
  }


}